var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "pr-2" : null,
          attrs: { justify: "center" },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column mb-lg-9 pt-16 mb-16",
              attrs: { cols: "12", lg: "8", md: "8", xl: "8", sm: "12" },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "text-h6 mx-auto mb-4 indigo--text text--darken-4 font-weight-bold",
                },
                [_vm._v(_vm._s(_vm.$t("welcomeSignin")))]
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-center mx-auto my-auto" },
                [
                  _c("v-img", {
                    attrs: {
                      width: "220",
                      height: "43",
                      src: require("@/assets/images/logo.png"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass:
                    "mx-auto mt-12 grey-darken-2--text font-weight-medium body-2",
                },
                [_vm._v(_vm._s(_vm.$t("signIn")))]
              ),
              _c("v-divider", {
                staticClass: "mx-auto mb-10 v-divider-style grey darken-1",
                attrs: { width: "76", height: "2" },
              }),
              _c(
                "v-alert",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isAuthorizationFailed,
                      expression: "isAuthorizationFailed",
                    },
                  ],
                  attrs: {
                    color: "red",
                    dismissible: "",
                    text: "",
                    icon: "mdi-alert-circle-outline",
                    dense: "",
                    type: "error",
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "my-auto font-weight-regular caption" },
                    [_vm._v(_vm._s(_vm.failureMessage))]
                  ),
                ]
              ),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.userSignin.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: " ",
                      "persistent-placeholder": "",
                      autofocus: "",
                      type: "email",
                      "error-messages": _vm.emailErrors,
                      label: _vm.$t("emailAddress"),
                      "prepend-inner-icon": "mdi-account-outline",
                      outlined: "",
                      required: "",
                      autocomplete: "email",
                    },
                    on: {
                      input: _vm.onEmailChange,
                      blur: function ($event) {
                        return _vm.$v.email.$touch()
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.userSignin.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-1",
                    attrs: {
                      placeholder: " ",
                      "persistent-placeholder": "",
                      outlined: "",
                      "hide-details": "true",
                      "prepend-inner-icon": "mdi-lock-outline",
                      "append-icon": _vm.showPassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      type: _vm.showPassword ? "text" : "password",
                      label: _vm.$t("password"),
                      autocomplete: "current-password",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPassword = !_vm.showPassword
                      },
                      contextmenu: function ($event) {
                        $event.preventDefault()
                      },
                      copy: function ($event) {
                        $event.preventDefault()
                      },
                      input: _vm.onPasswordChange,
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.userSignin.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c(
                    "v-row",
                    { staticClass: "pr-3", attrs: { justify: "end" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "body-2 mb-11 mt-4 text-decoration-none primary--text",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("forgotPasswordClick")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("forgotPassword")) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4 text-none rounded-lg",
                      attrs: {
                        block: "",
                        color: "primary",
                        disabled: _vm.$v.$invalid || _vm.isAuthorizationFailed,
                        type: "submit",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("signIn")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-9 mb-9 d-flex flex-row mx-auto" },
                [
                  _c("span", { staticClass: "body-2" }, [
                    _vm._v(_vm._s(_vm.$t("dontHaveAccountText"))),
                  ]),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "ml-lg-3 ml-md-2 ml-3 d-inline-flex body-2 text-decoration-none primary--text font-weight-medium",
                      attrs: { to: "/signup" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("signUp")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }