<template>
  <v-container>
    <v-row
      justify="center"
      :class="$vuetify.breakpoint.smAndDown ? 'pr-2' : null"
    >
      <v-col
        cols="12"
        lg="8"
        md="8"
        xl="8"
        sm="12"
        class="d-flex flex-column mb-lg-9 pt-16 mb-16"
      >
        <span
          class="text-h6 mx-auto mb-4 indigo--text text--darken-4 font-weight-bold"
          >{{ $t("welcomeSignin") }}</span
        >
        <div class="d-flex justify-center mx-auto my-auto">
          <v-img
            width="220"
            height="43"
            class=""
            src="@/assets/images/logo.png"
          ></v-img>
        </div>
        <span
          class="mx-auto mt-12 grey-darken-2--text font-weight-medium body-2"
          >{{ $t("signIn") }}</span
        >
        <v-divider
          width="76"
          height="2"
          class="mx-auto mb-10 v-divider-style grey darken-1"
        ></v-divider>
        <v-alert
          v-show="isAuthorizationFailed"
          color="red"
          dismissible
          text
          icon="mdi-alert-circle-outline"
          dense
          type="error"
        >
          <span class="my-auto font-weight-regular caption">{{
            failureMessage
          }}</span>
        </v-alert>
        <form @submit.prevent="userSignin">
          <v-text-field
            v-model="email"
            placeholder=" "
            persistent-placeholder
            autofocus
            type="email"
            :error-messages="emailErrors"
            :label="$t('emailAddress')"
            prepend-inner-icon="mdi-account-outline"
            outlined
            required
            @input="onEmailChange"
            @blur="$v.email.$touch()"
            @keyup.enter="userSignin"
            autocomplete="email"
          ></v-text-field>
          <v-text-field
            v-model="password"
            placeholder=" "
            persistent-placeholder
            outlined
            hide-details="true"
            prepend-inner-icon="mdi-lock-outline"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('password')"
            class="mb-1"
            @click:append="showPassword = !showPassword"
            @click.right.prevent
            @copy.prevent
            @input="onPasswordChange"
            @keyup.enter="userSignin"
            autocomplete="current-password"
          ></v-text-field>
          <v-row justify="end" class="pr-3">
            <a
              href="javascript:void(0)"
              class="body-2 mb-11 mt-4 text-decoration-none primary--text"
              @click="$emit('forgotPasswordClick')"
            >
              {{ $t("forgotPassword") }}
            </a>
          </v-row>
          <v-btn
            block
            class="mr-4 text-none rounded-lg"
            color="primary"
            :disabled="$v.$invalid || isAuthorizationFailed"
            type="submit"
          >
            {{ $t("signIn") }}
          </v-btn>
        </form>
        <div class="mt-9 mb-9 d-flex flex-row mx-auto">
          <span class="body-2">{{ $t("dontHaveAccountText") }}</span>
          <router-link
            to="/signup"
            class="ml-lg-3 ml-md-2 ml-3 d-inline-flex body-2 text-decoration-none primary--text font-weight-medium"
          >
            {{ $t("signUp") }}
          </router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import ERRORS from "@/static/errors.json";
import { setAuthDetails } from "@/store/utils/utils";
export default {
  name: "SignIn",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required },
  },
  data: () => ({
    email: "",
    password: "",
    showPassword: false,
    isAuthorizationFailed: false,
    failureMessage: "",
  }),
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("emailValidError"));
      !this.$v.email.required && errors.push(this.$t("emailRequiredError"));
      return errors;
    },
  },
  methods: {
    onEmailChange() {
      this.isAuthorizationFailed = false;
      this.$v.email.$touch();
    },
    onPasswordChange() {
      this.isAuthorizationFailed = false;
    },
    async userSignin() {
      if (this.$v.$invalid || this.isAuthorizationFailed) return;
      const varificationStatus = await this.$store.dispatch("authorizeUser", {
        email: this.email,
        password: this.password,
      });
      if (
        varificationStatus.status === "error" &&
        varificationStatus.error === ERRORS.INACTIVE_USER
      ) {
        this.isAuthorizationFailed = true;
        this.failureMessage = "Inactive User";
      }

      if (
        varificationStatus.status === "error" &&
        (varificationStatus.error === ERRORS.INVALID_PASSWORD ||
          varificationStatus.error === ERRORS.INVALID_USER)
      ) {
        this.isAuthorizationFailed = true;
        this.failureMessage = "Invalid username or password";
      } else if (
        varificationStatus.status === "error" &&
        varificationStatus.error === ERRORS.INACTIVE_COMPANY
      ) {
        this.isAuthorizationFailed = true;
        this.failureMessage = "User company is inactive";
      } else {
        this.$store.commit("setUserData", {
          email: this.email,
        });
        if (varificationStatus.status === "success") {
          setAuthDetails({
            userId: varificationStatus.user_id,
            token: varificationStatus.token,
          });
          this.$emit("userAuthSuccess");
        } else if (varificationStatus.message == "MFA code sent") {
          this.$emit("mfaCodeSent");
        }
      }
    },
    isForgotPassword() {},
  },
};
</script>

<style scoped>
.v-divider-style {
  border-width: 1.2px !important;
}
</style>
